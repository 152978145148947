<template>
    <div>
        <Map/>
        <Right/>
    </div>
</template>

<script>
import Map from './components/Map'
import Right from './components/Right'

// 引入store模块
import todStoreModule from '@/store/modules/tod'

export default {
  components: { Map, Right },
  created () {
    // 注册动态store模块
    if (!this.$store.hasModule('tod')) {
      this.$store.registerModule('tod', todStoreModule)
    }
  },
  destroyed () {
    // 卸载动态store模块
    this.$store.unregisterModule('tod')
  }
}
</script>
