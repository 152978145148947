<style scoped>
.tod-right{
  position: fixed;
  padding: 0 10px;
  right: 2px;
  width: 230px;
  top: 2px;
  bottom: 2px;
  z-index: 9;
  background-color:rgba(128,134,149, 0.3);
  overflow-y: scroll;
  scrollbar-width: none; /* FireFox */
  -ms-overflow-style: none; /*IE10+*/
}
.tod-right::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
</style>

<template>
    <div class="tod-right">
        <Divider dashed>商家查询</Divider>
        <p class="title">适配范围( <span class="text-orange">{{ GenerationCondition.distance }}</span> 米)</p>
        <Row>
            <i-col span="24">
                <Slider v-model="GenerationCondition.distance" :max="1000" :step="50" @on-change="conditionChange" ></Slider>
            </i-col>
        </Row>

        <p class="title">地铁站点</p>
        <Select size="small" v-model="GenerationCondition.stationId" filterable clearable @on-change="conditionChange">
            <Option v-for="item in allStationList" :value="item.id" :key="item.id">{{ item.name }}</Option>
        </Select>

        <p class="title p-t-10">商家分类</p>
        <Tree :data="typeData" @on-select-change="handleChangeType"></Tree>
    </div>
</template>

<script>
import { listBusinessType } from '@/api/dmp/business'
import { assetList } from '@/api/product/category'
import { getStationByAssetIds } from '@/api/product/station'

export default {
  data () {
    return ({
      typeData: [],
      allAssets: [],
      allStationList: [], // 全部站点
      GenerationCondition: {
        distance: 500,
        stationId: null,
        middleTypeCode: null,
        bigTypeCode: null
      }
    })
  },
  created () {
    this.initPageData()
  },
  methods: {
    initPageData () {
      // 获取全部线路的人流量
      assetList({ publisherId: this.$store.getters.token.userInfo.publisherId }).then(assets => {
        this.allAssets = assets
        const query = { assetIds: JSON.stringify(assets.map(x => x.id)) }

        getStationByAssetIds(query).then(res => {
          this.allStationList = res
        })
      })

      listBusinessType().then(res => {
        // 格式化树形菜单
        const bigType = []
        res.forEach(element => {
          // 处理父节点
          if (bigType.indexOf(element.bigTypeCode) === -1) {
            bigType.push(element.bigTypeCode)
            const middleTypeData = res.filter(x => x.bigTypeCode === element.bigTypeCode)
            const middleTypeChildren = []
            const middleType = []

            middleTypeData.forEach(middle => {
              if (middleType.indexOf(middle.middleTypeCode) === -1) {
                middleType.push(middle.middleTypeCode)
                middleTypeChildren.push({
                  title: middle.middleType,
                  middleTypeCode: middle.middleTypeCode,
                  expand: false
                })
              }
            })

            this.typeData.push({
              title: element.bigType,
              expand: false,
              bigTypeCode: element.bigTypeCode,
              children: middleTypeChildren
            })
          }
        })
      })
    },
    conditionChange () {
      this.$store.commit('set_tod_searchCondition', this.GenerationCondition)
      this.$store.commit('set_tod_updateSearch', new Date())
    },
    handleChangeType (val) {
      this.GenerationCondition.middleTypeCode = val[0].middleTypeCode
      this.GenerationCondition.bigTypeCode = val[0].bigTypeCode

      this.$store.commit('set_tod_searchCondition', this.GenerationCondition)
      this.$store.commit('set_tod_updateSearch', new Date())
    }
  }
}
</script>
