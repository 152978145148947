const tod = {
  state: {
    searchCondition: {},
    updateSearch: new Date()
  },
  mutations: {
    set_tod_searchCondition (state, data) {
      state.searchCondition = data
    },
    set_tod_updateSearch (state, data) {
      state.updateSearch = data
    }
  },
  getters: {

  },
  actions: {

  }
}

export default tod
